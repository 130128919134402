<template>
  <!--        :class="{ bg_dark: is_dark_mode }"-->
  <div
      class="section_wrap pt-50 pb-80 bg_dark full-height overflow-y-auto"
      ref="post_list_item_view"
  >
    <ul v-if="items.length > 0"
        class="container flex-column justify-center w-100 pa-20"
    >
      <li class="bg-white mb-20 minting_card"
          v-for="(item, index) in items"
          :key="'item_' + index"
          :style="'background-image: url(' + item.sl_img_file_path + ');'"
          @click="goDetail(item)"
      >
        <div class="minting_bg">
          <div>
            <span style="color: white; font-size: 13px;">Date. {{ item.sl_startdt }} ~ {{ item.sl_enddate }}</span>
          </div>
          <div class="flex justify-space-between">
            <div></div>
            <button
                v-if="item.sl_state_code === 'SA00200001' || item.sl_state_code === 'SA00200002'"
                style="color: white; border: 1px solid white; padding: 5px 11px; border-radius: 20px; font-size: 12px;  opacity: 0.6;"
            >
              COMING SOON
            </button>
            <button
                v-if="item.sl_state_code === 'SA00200003'"
                style="color: white; border: 1px solid white; padding: 5px 11px; border-radius: 20px; font-size: 12px"
            >
              {{ $language.launchpad.view }}
            </button>
            <button
                v-if="item.sl_state_code === 'SA00200004'"
                style="color: white; border: 1px solid white; padding: 5px 11px; border-radius: 20px; font-size: 12px; opacity: 0.6;"
            >
              {{ $language.launchpad.minting_ended }}
            </button>
            <button
                v-if="item.sl_state_code === 'SA00200005'"
                style="color: white; border: 1px solid white; padding: 5px 11px; border-radius: 20px; font-size: 12px;  opacity: 0.6;"
            >
              SOLD OUT
            </button>
          </div>
        </div>
        <div class="minting_text">
          <p class="size-px-12" style="color: #BBB; opacity: 0.75">{{ item.cartl_name }}</p>
          <h1 class="color-white">{{ item.sl_name }}</h1>
          <div class="flex-row">
            <h5 class="color-white size-px-13">&#x20A9;{{ Number(item.sl_price) | makeComma }}</h5>
          </div>
        </div>
      </li>
    </ul>
    <div
        v-else
        class="character3 pt-130 text-center size-px-16 color-white full-height" style="margin-top: 50%"
    >판매중인 NFT 내역이 없습니다.
    </div>

    <SaleInappDetail
       v-if="is_on_inapp"
       :user="user"
       :item="item_inapp"

       @closePopup="is_on_inapp=false"
     ></SaleInappDetail>

	<button @click="toOpenLaunchpad" class="position-fixed btn_s btn_fill_blue" style="right: 10px; bottom: 90px">런치패드 신청</button>
  </div>
</template>

<script>

import SaleInappDetail from "@/view/Launchpad/SaleInappDetail";
export default {
  name: "SaleNftList"
  ,
	components: {SaleInappDetail},
	props: ['user']
  , data() {
    return {
      program: {
        name: 'SaleNftList'
      }
      , is_dark_mode: true
      , items: []
      , is_on_inapp: false
      , item_inapp: {}
    }
  }
  , methods: {
    getData: async function () {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$Request({
          method: 'post'
          , url: this.$api_url.api_path.get_sale_nft_list
          , data: {
            member_number: this.user.member_number
          }
          , type: true
        })

        if (result.success) {
          console.log(result.data, 'sale_nft_list')
          this.items = result.data.cartl_nft_sl_list
        } else {
          throw result.message
        }

      } catch (e) {
        this.$bus.$emit('notify', {type: 'success', message: e, config: {bottom: 0}})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
		, goDetail(item) {
		console.log('goDetail')
			this.$router.push({
				name: 'SaleNftDetail',
				params: {
					nft_number: item.cartl_nft_sl_number
					, state_code: item.sl_state_code
				}
			})
		}
    , goDetail2(item) {
		try {

			if(this.$common.getMobile() == 'ios') {
				this.$common.inAppWebviewCommunity('android', 'AP00500002')

				this.item_inapp = item
				this.is_on_inapp = true
			}else{
				throw ''
			}
		}catch (e){
			this.$router.push({
				name: 'SaleNftDetail',
				params: {
					nft_number: item.cartl_nft_sl_number
					, state_code: item.sl_state_code
				}
			})
		}
    }
		, toOpenLaunchpad: function(){

			try {
				console.log('open window app')
				this.$common.inAppWebviewCommunity('android', 'outUrl:forms.gle/2PXDFYXPmHtFTmFj8')
			}catch (e){
				console.log('open window web')
				window.open('https://forms.gle/2PXDFYXPmHtFTmFj8', 'openlaunchpad')
			}
		}
  }
  , created() {
    this.$emit('onLoad', this.program)
    this.getData()
  }
}
</script>

<style scoped>
button:disabled {
  background-color: transparent;
}

.minting_card {
  width: 100%;
  height: 240px;
  max-width: 42.5rem;
  border-radius: 1.5rem;
}

.minting_bg {
  width: 100%;
  height: 55%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.minting_text {
  width: 100%;
  height: 45%;
  background-color: #181A39;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding: 20px;
}
</style>